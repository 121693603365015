<template>
  <div>
    <div class="header-invoice">
      <v-row justify="space-between">
        <v-icon class="back-button" @click="$router.go(-1)">
          {{ icons.mdiChevronLeft }}
        </v-icon>
        <h3 class="font-weight-bold">Detail Invoice</h3>
        <v-btn depressed color="white" class="text-capitalize cmd-button" outlined>
          <v-icon dense color="white">
            {{ icons.mdiDownload }}
          </v-icon>
          Download
        </v-btn>
      </v-row>
    </div>
    <v-row class="mt-10 mx-10" justify="space-between">
      <div>
        <p>[Logo superwa] <span style="color: #f15858" class="font-weight-bold">Super WA</span></p>
        <div>
          <p class="font-weight-bold" style="color: black; margin-top: -10px">Invoice number</p>
          <p style="margin-top: -18px">INV/20201114/XX/XI/675206164</p>
        </div>
        <div class="mt-5">
          <p class="font-weight-bold" style="color: black">Date paid</p>
          <p style="margin-top: -18px">28 Aug 2022, 11:36</p>
        </div>
        <p
          class="paid-badge .padi-badge{ background-color:#29CC6A;width:50px;text-align:center;color:white;padding-block:4px;border-radius:5px;font-size:13px }"
        >
          Paid
        </p>
      </div>
      <div>
        <p>From</p>
        <div>
          <p class="font-weight-bold" style="color: black; margin-top: -10px">Cartenz</p>
          <p style="margin-top: -18px; max-width: 250px">
            Gedung AIA Central, Lantai 25 Jln. Jend. Sudirman Kav 48A, Jakarta Selatan, 12930
          </p>
        </div>
        <div class="mt-5">
          <p>Phone number: 021-29772929</p>
          <p style="margin-top: -18px">Email: contact@cartenz.co.id</p>
        </div>
      </div>
      <div>
        <p>To</p>
        <div>
          <p class="font-weight-bold" style="color: black; margin-top: -10px">Nurdien</p>
          <p style="margin-top: -18px; max-width: 250px">
            Dusun Sukamulya RT 11 RW 03, Desa Paseh Kidul, Kecamatan Paseh, Kab. Sumedang
          </p>
        </div>
        <div class="mt-5">
          <p>Phone number: 021-29772929</p>
          <p style="margin-top: -18px">Email: contact@cartenz.co.id</p>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import { mdiChevronLeft, mdiDownload } from '@mdi/js'

export default {
  name: 'DetailInvoice',
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')

    return {
      isDark,
      search,
      icons: {
        mdiChevronLeft,
        mdiDownload,
      },
    }
  },
}
</script>

<style>
.header-invoice {
  background-color: #f15858;
  padding-block: 15px;
  padding-inline: 30px;
  color: white;
  text-align: center;
}
.paid-badge {
  background-color: #29cc6a;
  width: 50px;
  text-align: center;
  color: white;
  padding-block: 4px;
  border-radius: 5px;
  font-size: 13px;
}
</style>
